import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'MSR Deck Only Cutaway' },
    { img: data.i2, caption: 'MSR 22 ga PROFILE' },
    { img: data.i3, caption: 'MSR 20 ga PROFILE' },
    { img: data.i4, caption: 'MSR on ISP illustration' },
    { img: data.i5, caption: 'Epicore MSR Deck Bearing on 6″ Wall' },
    { img: data.i6, caption: 'Epicore MSR Bearing on 6″ Wall' },
    { img: data.i7, caption: 'Epicore MSR 22 ga - On 3-5/8″ Wall' },
    { img: data.i8, caption: 'Epicore MSR 22 ga bearing - From Below' },
    { img: data.i9, caption: 'Epicore MSR 20 ga Bearing on wall' },
    { img: data.i10, caption: 'Edge Screed/Pour Stop Angle - Ready For Concrete Sub' },
    { img: data.i11, caption: 'Epicore MSR Deck Ready to Pour' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: EPICORE MSR Composite Floor System" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>EPICORE MSR Composite Floor System</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>The Epicore MSR Composite Floor System is a high performance composite deck that
            is ideally suited for use with load-bearing metal stud walls. The Epicore MSR deck
            profile is flat across the bottom which creates a continuous uniform load on the metal
            stud bearing walls. The dovetail ribs, closed flutes, and pinched down ends block any
            sound, fire and smoke transfer from unit-to-unit over demising walls. The Epicore MSR
            can be designed based on simple-span structural design (no negative rebar) or based on
            continuous span design (negative rebar over bearing walls) depending upon the span.</p>
            <p className='type--lg'>
              <Link className="link-next" to="/system-overview/mep-in-slab/">
                Next Page: M/E/P in Slab
              </Link>
            </p>
            <p>
              <Link className="link-prev" to="/system-overview/lateral-loads/">
                Prev Page: Lateral Loads
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/01-41612.png" }) {
            ...SystemOverviewCarouselTechnical1_5ImageFragment },
        i2: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/02-41621.png" }) {
            ...SystemOverviewCarouselTechnical1_5ImageFragment },
        i3: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/03-41622.png" }) {
            ...SystemOverviewCarouselTechnical1_5ImageFragment },
        i4: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/04-41613.png" }) {
            ...SystemOverviewCarouselTechnical1_5ImageFragment },
        i5: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/05-MSRDk946_(42928).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i6: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/06-MSRDk954_(42936).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i7: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/07-MSRDk150_(34276).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i8: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/08-MSRDk850_(33938).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i9: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/09-MSRDk943_(42925).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i10: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/10-MSRDk160_(34277).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i11: file(relativePath: { eq: "system-overview/epicore-msr-floor/gallery/11-MSRDk240_(34330).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`
